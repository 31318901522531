import '../scss/top-menu-primary.scss'

document.addEventListener('DOMContentLoaded', () => {
    const dropdownAnchors = document.querySelectorAll('.nav-link.dropdown-toggle');

    dropdownAnchors.forEach(dropdownAnchor => {
        dropdownAnchor.addEventListener('mouseenter', () => {
            const dropdownMenu = dropdownAnchor.nextElementSibling;
            dropdownAnchor.classList.add('show');
            dropdownMenu.classList.add('show');
        });

        dropdownAnchor.parentElement.addEventListener('mouseleave', () => {
            const dropdownMenu = dropdownAnchor.nextElementSibling;
            dropdownAnchor.classList.remove('show');
            dropdownMenu.classList.remove('show');
        });
    });
});